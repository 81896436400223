import React,{useState} from 'react'
import HeaderSecond from "../../components/Secondheader.js"

function Connects() {





  return (
    <div className="App"> 

    <div id="wrapper">
        <HeaderSecond/>



        <div id="main">
      <div id="lightbox" class="section bg-gray">
        <div class="container">
          <div class="row animated-row">
            <div class="col col-md-9 blog-col">
              <div class="bimm-mates-header">
                <div class="bimm-mates-header-btn">
                  <a href="bimmates.html" class="btn btn-icon btn-outline m-r-10 header-btn-active"><i
                      class="fa fa-bullhorn" aria-hidden="true"></i><span>BIM PROFESSIONALS</span></a>
                  <a href="bimcompanies.html" class="btn btn-icon btn-outline m-r-10"><i class="fa fa-bullhorn"
                      aria-hidden="true"></i><span>BIM COMPANIES</span></a>
                  <a href="#" class="btn btn-icon btn-outline m-r-10"><i class="fa fa-bullhorn"
                      aria-hidden="true"></i><span>BIM INSTITUTES</span></a>
                </div>
                <div class="sidebar-search d-block">
                  <form action="#">
                    <input class="form-control" type="search" placeholder="Search here..." />
                    <button type="submit" class="btn-search">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                <div class="d-none m-b-20 m-t-20" style={{width:"100%"}}>
                  <div class="mobile-filter">
                    <form action="#">
                      <input class="form-control" type="search" placeholder="Search here..." />
                      <button type="submit" class="btn-search">
                        <i class="fa fa-search"></i>
                      </button>
                    </form>
                    <h4 class="calculation-filter">
                      <a data-toggle="collapse" data-parent="#accordion-01" href="#collapse5">Filter<span
                          class="pull-right icon-acc"><i class="fa fa-angle-down"></i></span></a>
                    </h4>
                  </div>
                </div>
              </div>
              <div id="collapse5" class="panel-collapse collapse calculation-collapse">
                <div class="panel-body">
                  <div class="blog-sidebar">
                    <div class="
                          sidebar-widget
                          m-b-30
                          md-m-b-20
                          p-30
                          md-p-20
                          animate
                        " data-animate="fadeInUp">
                      <h4>Designation</h4>
                      <div>
                        <div class="sidebar-search">
                          <form action="#">
                            <input class="form-control" type="search" placeholder="Search designation..." />
                            <button type="submit" class="btn-search">
                              <i class="fa fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>

                      <ul class="list list-style-01 text-uppercase text-md">
                        <li>
                          <a href="#">Architect</a>
                          <span class="number">(12)</span>
                        </li>
                        <li>
                          <a href="#">Modeller</a>
                          <span class="number">(10)</span>
                        </li>
                      </ul>
                    </div>
                    

                    <div class="
                          sidebar-widget
                          m-b-30
                          md-m-b-20
                          p-30
                          md-p-20
                          animate
                        " data-animate="fadeInUp">
                      <h4>Country</h4>

                      <div class="sidebar-search">
                        <form action="#">
                          <input class="form-control" type="search" placeholder="Search designation..." />
                          <button type="submit" class="btn-search">
                            <i class="fa fa-search"></i>
                          </button>
                        </form>
                      </div>
                      <ul class="list list-style-01 text-uppercase text-md">
                        <li>
                          <a href="#">UAE</a> <span class="number">(12)</span>
                        </li>
                        <li>
                          <a href="#">UK</a> <span class="number">(10)</span>
                        </li>
                        <li>
                          <a href="#">USA</a> <span class="number">(12)</span>
                        </li>
                        <li>
                          <a href="#">CANADA</a>
                          <span class="number">(12)</span>
                        </li>
                      </ul>
                    </div>
                    

                    <div class="
                          sidebar-widget
                          m-b-30
                          md-m-b-20
                          p-30
                          md-p-20
                          animate
                        " data-animate="fadeInUp">
                      <h4>Experience</h4>
                      <ul class="list list-style-01 text-uppercase text-md">
                        <li>
                          <a href="#">Fresher</a>
                          <span class="number">(12)</span>
                        </li>
                        <li>
                          <a href="#">1-2 years</a>
                          <span class="number">(10)</span>
                        </li>
                        <li>
                          <a href="#">3-5 years</a>
                          <span class="number">(12)</span>
                        </li>
                      </ul>
                    </div>
                
                    <div class="
                          sidebar-widget
                          m-b-30
                          md-m-b-20
                          p-30
                          md-p-20
                          animate
                        " data-animate="fadeInUp">
                      <h4>COMPANY</h4>
                      <div class="sidebar-search">
                        <form action="#">
                          <input class="form-control" type="search" placeholder="Search designation..." />
                          <button type="submit" class="btn-search">
                            <i class="fa fa-search"></i>
                          </button>
                        </form>
                      </div>
                      <ul class="list list-style-01 text-uppercase text-md">
                        <li>
                          <a href="#">ATKINS</a>
                          <span class="number">(12)</span>
                        </li>
                        <li>
                          <a href="#">AECOM</a>
                          <span class="number">(10)</span>
                        </li>
                        <li>
                          <a href="#">KEO</a> <span class="number">(12)</span>
                        </li>
                        <li>
                          <a href="#">CHINA STATE</a>
                          <span class="number">(12)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row portfolio-gallery">
                <div class="col col-xs-6 col-sm-4">
                  <div class="testimonial-box1 border text-lg m-b-10">

                    <div class="
                            client-imgbox3
                            bimmates-client-box bimmates-box
                            
                          " style={{display:"flex" , alignItems:"center"}} >
                      <figure class="portfolio-box">
                        <img src="https://i.pravatar.cc/150?img=2" alt="" />

                        <div class="portfolio-btn portfolio-btn1 connect-btn" style={{top:"44px" ,left:"25px"}}>
                          <a href="#">connect</a>
                        </div>
                        <div class="portfolio-overlay">
                          <div class="portfolio-content"></div>
                        </div>
                      </figure>

                      <div class="client-details"style={{textAlign:"left" ,fontSize:"13px"}}>
                        <strong>Lia Shelton</strong> <br />
                        <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">BIM Engineer @ E construct</sub>
                        <br />
                        <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">Dubai, UAE</sub>
                        <br />
                        <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">44 Mutual Connection</sub>
                        <br />
                      </div>
                    </div>
                    {/* </a> */}
                  </div>
                  
                </div>
                <div class="col col-xs-6 col-sm-4">
                  <div class="testimonial-box1 border text-lg m-b-10">

                      <div class="
                            client-imgbox3
                            bimmates-client-box bimmates-box
                          " style={{display:"flex" , alignItems:"center"}}>
                        <figure class="portfolio-box">
                          <img src="https://i.pravatar.cc/150?img=2" alt="" />

                          <div class="portfolio-btn portfolio-btn1 connect-btn" style={{top:"44px" , left:"25px"}} >
                            <a href="#">connect</a>
                          </div>
                          <div class="portfolio-overlay">
                            <div class="portfolio-content"></div>
                          </div>
                        </figure>
                        <div class="client-details" style={{textAlign:"left" , fontSize:"13px"}} >
                          <strong>Lia Shelton</strong> <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">BIM Engineer @ E construct</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">Dubai, UAE</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">44 Mutual Connection</sub>
                          <br />
                        </div>
                      </div>

                  </div>
                  
                </div>
                <div class="col col-xs-6 col-sm-4">
                  <div class="testimonial-box1 border text-lg m-b-10">

                      <div class="
                            client-imgbox3
                            bimmates-client-box bimmates-box
                          " style={{display:"flex" , alignItems:"center"}}>
                        <figure class="portfolio-box">
                          <img src="https://i.pravatar.cc/150?img=2" alt="" />

                          <div class="portfolio-btn portfolio-btn1 connect-btn" style={{top:"44px" , left:"25px"}} >
                            <a href="#">connect</a>
                          </div>
                          <div class="portfolio-overlay">
                            <div class="portfolio-content"></div>
                          </div>
                        </figure>
                        <div class="client-details" style={{textAlign:"left" , fontSize:"13px"}} >
                          <strong>Lia Shelton</strong> <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">BIM Engineer @ E construct</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">Dubai, UAE</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">44 Mutual Connection</sub>
                          <br />
                        </div>
                      </div>

                  </div>
                  
                </div>
                <div class="col col-xs-6 col-sm-4">
                  <div class="testimonial-box1 border text-lg m-b-10">

                      <div class="
                            client-imgbox3
                            bimmates-client-box bimmates-box
                          " style={{display:"flex" , alignItems:"center"}} >
                        <figure class="portfolio-box">
                          <img src="https://i.pravatar.cc/150?img=2" alt="" />

                          <div class="portfolio-btn portfolio-btn1 connect-btn" style={{top:"44px" , left:"25px"}}>
                            <a href="#">connect</a>
                          </div>
                          <div class="portfolio-overlay">
                            <div class="portfolio-content"></div>
                          </div>
                        </figure>
                        <div class="client-details" style={{textAlign:"left" , fontSize:"23px"}} >
                          <strong>Lia Shelton</strong> <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">BIM Engineer @ E construct</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">Dubai, UAE</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">44 Mutual Connection</sub>
                          <br />
                        </div>
                      </div>

                  </div>
                  
                </div>
                <div class="col col-xs-6 col-sm-4">
                  <div class="testimonial-box1 border text-lg m-b-10">

                      <div class="
                            client-imgbox3
                            bimmates-client-box bimmates-box
                          " style={{display:"flex" , alignItems:"center"}}>
                        <figure class="portfolio-box">
                          <img src="https://i.pravatar.cc/150?img=2" alt="" />

                          <div class="portfolio-btn portfolio-btn1 connect-btn" style={{top:"44px" , left:"25px"}} >
                            <a href="#">connect</a>
                          </div>
                          <div class="portfolio-overlay">
                            <div class="portfolio-content"></div>
                          </div>
                        </figure>
                        <div class="client-details" style={{textAlign:"left" , fontSize:"13px"}} >
                          <strong>Lia Shelton</strong> <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">BIM Engineer @ E construct</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">Dubai, UAE</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">44 Mutual Connection</sub>
                          <br />
                        </div>
                      </div>

                  </div>
                
                </div>
                <div class="col col-xs-6 col-sm-4">
                  <div class="testimonial-box1 border text-lg m-b-10">

                      <div class="
                            client-imgbox3
                            bimmates-client-box bimmates-box
                          " style={{display:"flex" , alignItems:"center"}}>
                        <figure class="portfolio-box">
                          <img src="https://i.pravatar.cc/150?img=2" alt="" />

                          <div class="portfolio-btn portfolio-btn1 connect-btn" style={{top:"44px" , left:"25px"}} >
                            <a href="#">connect</a>
                          </div>
                          <div class="portfolio-overlay">
                            <div class="portfolio-content"></div>
                          </div>
                        </figure>
                        <div class="client-details"style={{textAlign:"left" , fontSize:"13px"}} >
                          <strong>Lia Shelton</strong> <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">BIM Engineer @ E construct</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">Dubai, UAE</sub>
                          <br />
                          <sub class="
                                title-style-02
                                client-designation
                                text-lightgray
                              ">44 Mutual Connection</sub>
                          <br />
                        </div>
                      </div>

                  </div>
                
                </div>
              </div>
            </div>

            <aside class="col col-md-3 blog-sidebar d-block">
              <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Designation</h4>
                <div>
                  <div class="sidebar-search">
                    <form action="#">
                      <input class="form-control" type="search" placeholder="Search designation..." />
                      <button type="submit" class="btn-search">
                        <i class="fa fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>

                <ul class="list list-style-01 text-uppercase text-md">
                  <li>
                    <a href="#">Architect</a> <span class="number">(12)</span>
                  </li>
                  <li>
                    <a href="#">Modeller</a> <span class="number">(10)</span>
                  </li>
                </ul>
              </div>
              

              <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Country</h4>

                <div class="sidebar-search">
                  <form action="#">
                    <input class="form-control" type="search" placeholder="Search designation..." />
                    <button type="submit" class="btn-search">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li>
                    <a href="#">UAE</a> <span class="number">(12)</span>
                  </li>
                  <li><a href="#">UK</a> <span class="number">(10)</span></li>
                  <li>
                    <a href="#">USA</a> <span class="number">(12)</span>
                  </li>
                  <li>
                    <a href="#">CANADA</a> <span class="number">(12)</span>
                  </li>
                </ul>
              </div>
              

              <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Experience</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li>
                    <a href="#">Fresher</a> <span class="number">(12)</span>
                  </li>
                  <li>
                    <a href="#">1-2 years</a> <span class="number">(10)</span>
                  </li>
                  <li>
                    <a href="#">3-5 years</a> <span class="number">(12)</span>
                  </li>
                </ul>
              </div>
              
              <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>COMPANY</h4>
                <div class="sidebar-search">
                  <form action="#">
                    <input class="form-control" type="search" placeholder="Search designation..." />
                    <button type="submit" class="btn-search">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li>
                    <a href="#">ATKINS</a> <span class="number">(12)</span>
                  </li>
                  <li>
                    <a href="#">AECOM</a> <span class="number">(10)</span>
                  </li>
                  <li>
                    <a href="#">KEO</a> <span class="number">(12)</span>
                  </li>
                  <li>
                    <a href="#">CHINA STATE</a>
                    <span class="number">(12)</span>
                  </li>
                </ul>
              </div>
            
            </aside>
          </div>
        </div>
      </div>
    </div>
	




    
    
    </div>

     </div>
  );
}

export default Connects;
