import React,{useState} from 'react'

import Secondheader from '../../components/Secondheader';




function Projects() {

  return (
    <div className="App">

<Secondheader/>

<div id="main">
      <div id="lightbox" class="section bg-gray">
        <div class="container">
          <div class="row animated-row">
            <div class="col col-md-9 blog-col calculation-mob">
              <div class="row contribute-header">

                <div class="d-none" style={{width:"100%"}} >
                  <div class="mobile-filter">
                    <form action="#"> <input class="form-control" type="search" placeholder="Search here..." /> <button
                      type="submit" class="btn-search"><i class="fa fa-search"></i></button> </form>
                      <h4 class="calculation-filter "> <a data-toggle="collapse" data-parent="#accordion-01"
                        href="#collapse5">Filter<span class="pull-right icon-acc"><i
                            class="fa fa-angle-down"></i></span></a></h4>
                  </div>
                </div>


                <div id="collapse5" class="panel-collapse collapse calculation-collapse">
                  <div class="panel-body">
                    <div class="col col-md-3 blog-sidebar">
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>Location</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">Dubai</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                              </div>
                            </span> </li>
                          <li><a href="#">Sharjah</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                              </div>
                            </span></li>
                        </ul>
                      </div>
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>Type</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">Residential</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                              </div>
                            </span> </li>
                            <li><a href="#">Villa</a> <span class="number">
                                <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                </div>
                              </span> </li>
                          <li><a href="#">Commercial</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                              </div>
                            </span></li>
                            <li><a href="#">Hospital</a> <span class="number">
                                <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                </div>
                              </span> </li>
                        </ul>
                      </div>
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>Descipline</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">HVAC</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span> </li>
                          <li><a href="#">Lighting</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Power </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Water Supply </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Drainage </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>



                        </ul>
                      </div>
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>SORT BY</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">ALPHABETS ORDER</a><span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span> </li>
                          <li><a href="#">UPLOADED DATE</a><span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">POPULARITY</a><span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row portfolio-gallery">
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                      
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                     
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                      
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                      
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                     
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                     
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                     
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-4">
                  <div class="portfolio-card portfolio-proj-card">
                    <figure> <a href="projects1.html"> <img src="images/project-02.jpg" alt="" /></a>

                    </figure> <a href="projects1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <div >

                      <ul class="list list-style-02">
                        <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. Dn ut ullamcorpe.. </li>

                        <li><strong>Location:</strong> Marina, Dubai</li>


                      </ul>
                      
                    </div>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-heart"></i> 123 </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside class="col col-md-3 blog-sidebar">  <br />
              <div class="sidebar-search d-block" style={{marginBottom:"20px"}} >
                <form action="#"> <input class="form-control" type="search" placeholder="Search here..." /> <button
                    type="submit" class="btn-search"><i class="fa fa-search"></i></button> </form>
              </div>
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Location</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">Dubai</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span> </li>
                  <li><a href="#">Sharjah</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                </ul>
              </div>
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Type</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">Residential</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span> </li>
                    <li><a href="#">Villa</a> <span class="number">
                        <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        </div>
                      </span> </li>
                  <li><a href="#">Commercial</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                    <li><a href="#">Hospital</a> <span class="number">
                        <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        </div>
                      </span> </li>
                </ul>
              </div>
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Descipline</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">HVAC</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                      </div>
                    </span> </li>
                    <li><a href="#">Architect</a> <span class="number">
                        <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        </div>
                      </span> </li>
                  <li><a href="#">Drainage</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                    <li><a href="#">Interior</a> <span class="number">
                        <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                        </div>
                      </span> </li>




                </ul>
              </div>
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>SORT BY</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">ALPHABETS ORDER</a><span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span> </li>
                  <li><a href="#">UPLOADED DATE</a><span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">POPULARITY</a><span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                </ul>
              </div>

              <div class="sidebar-widget m-b-20 p-20 animate" data-animate="fadeInUp">
                <h4>Best Contributor of THE MONTH</h4>
                <div class="experience-list">
                  <div class="experience-row1 clearfix animated fadeIn" data-animate="fadeIn">
                    <div class="client-imgbox2">
                      <figure><img src="images/client-02.png" alt="" /></figure>
                    </div>
                    <div class="experience-details experience-details1 overflowhidden font-secondary"> <br/> <strong
                        class="text-uppercase text-black">SUHAIL ABOOBACKER</strong> <span>BIM Engineer</span> </div>
                    
                  </div>
                 

                </div>
              </div>
             
              <div class="sidebar-widget  m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Help BIM Mates</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                  industry's standard. Lorem Ipsum has been the industry's standard</p>
              </div>
            </aside>
            
          </div>
         
        </div>
     
      </div>
      
    </div>
	







     </div>
  );
}

export default Projects;
