
import './App.css';


import Home from "./pages/home.js"
import Signup from "./pages/signup/signup.js"
import Signin from "./pages/signin/signin"
import Secondheader from "./components/Secondheader.js"
import Projects from "./pages/projects/projects.js"
import Families from "./pages/famailies/families.js";
import Connects from "./pages/connects/connects.js";
import Calculation from "./pages/calculation/calculation.js";




import React from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Headers from "./components/header.js";


function App() {


  return (
    <div className="App">

<Router>
      
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/signup" element={<Signup/>}/>
          <Route exact path="/signin" element={<Signin/>}/>
          <Route exact path="/header2" element={<Secondheader/>}/>
          <Route exact path="/projects" element={<Projects/>}/>
          <Route exact path="/families" element={<Families/>}/>
          <Route exact path="/connects" element={<Connects/>}/>
          <Route exact path="/calculation" element={<Calculation/>}/>
        </Routes>
      

    </Router>






     </div>
  );
}

export default App;
