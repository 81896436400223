import React,{useState} from 'react'

import Footer from "../../components/footer";
import Header from "../../components/header";



function SignIn() {


  return (
    <div className="App">

 <Header/>
  

<div id="preloader">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>

    <div id="main-wrapper">

        <div class="authincation section-padding">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center" style={{paddingTop: "150px"}}>
                    <div class="col-md-4"></div>
                    <div class="col-xl-5 col-md-4 form-width">
                            <div class="mini-logo text-center my-5">
                                <a href="index.html"><img src="./images/logo.png" alt=""/></a>
                            </div>
                        <div class="auth-form card">
                            <div class="card-header justify-content-center">
                                <h4 class="modal-title" id="myModalLabel">Sign in</h4>
                            </div>
                            <div class="card-body">
                                <form action="#" id="hireform" method="post">
                                    <div class="row">
                                    <div class="col col-sm-12">
                                      <div class="input-field has-icon"> <i class="fa fa-user"></i>
                                        <input type="text" required class="form-control" name="hirename" placeholder="Email" />
                                      </div>
                                      
                                    </div>
                                 
                                    <div class="col col-sm-12">
                                      <div class="input-field has-icon"> <i class="fa fa-mobile"></i>
                                        <input type="tel" required class="form-control" name="hirephone" placeholder="Password" />
                                      </div>
                                      
                                    </div>
                                  

                                    <div class="col col-sm-12" style={{paddingLeft: "20px"}} >
                                        <label class="switch">
                                            <input type="checkbox" />
                                            <span class="slider round"></span>
                                          </label>
                                          <label class="chkLabel formfont-style">Remember me</label>
                                          <a href="reset.html" style={{float:"right"}}  class="formfont-style">Forgot Password?</a>
                                    </div>

                                         <br/>

                                    
                                    <div class="col col-xs-12 text-center">
                                      <button type="submit" class="btn btn-dark btn-lg btn-submit">Submit</button>
                                    </div>
                                  
                                    <div class="col col-xs-12">
                                      <div id="loading01"> Sending your message....</div>
                                      <div id="successmsg01">Your message has been sent.</div>
                                      <div id="errormsg01">Something went wrong, please try again.</div>
                                    </div>
                                    
                                  </div>
                                </form>
                                <div class="new-account mt-3 ">
                                    <p style={{textAlign: "center"}}  class="formfont-style">Don't have an account? <a href="/signup">Sign
                                            up</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </div>


    </div>


    <Footer/>


     </div>
  );
}

export default SignIn;
