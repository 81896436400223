import React,{useState} from 'react'


function Secondheader() {

  return (
    <div className="App">

<header id="header">
      <nav class="navbar m-0">
        <div class="container-fluid navigation-menu">
          <div class="row">
            <div class="col-sm-2 col-xs-7 navbar-header"> <span class="logobox"> <a id="logo" href="index.html"><img
                    src="images/logo.svg" alt="" /></a> </span>
           
            </div>
            <div class="navigation-row col-sm-10 col-xs-5"> <button type="button" class="navbar-toggle collapsed"
                data-toggle="collapse" data-target="#mainmenu"><span class="menulines"></span></button>
              <nav class="collapse navbar-collapse pull-right" id="mainmenu">
                <ul class="nav navbar-nav">

                  <li><a href="/">Home</a></li>
                  <li><a href="/families">Revit Families</a></li>

                  <li><a href="#">Design Data</a>
                    <ul>
                      <li><a href="">Regulations</a></li>
                      <li><a href="/calculation">Calculation Sheets</a></li>
                    </ul>
                  </li>
                  <li><a href="/projects">Projects</a></li>
                 
                  <li><a href="/connects">Connect BIM Mates</a></li>
                  <li class="hire-btn header-btn"><a href="/signin" data-toggle="modal" data-target="#hireme"> Login</a></li>
                </ul>
              </nav>
              
            </div>
          </div>
        </div>
      </nav>
    </header>
	

     </div>
  );
}

export default Secondheader;
