import React,{useState} from 'react'
import HeaderSecond from "../../components/Secondheader.js"

function Families() {





  return (
    <div className="App"> 

    <div id="wrapper">
        <HeaderSecond/>


    <div id="main">
  <div id="lightbox" class="section bg-gray">
    <div class="container">
      <div class="row animated-row">
        <div class="col col-md-9 blog-col">
          <div class="btn-list text-center m-30 p-b-10">
            <a href="requested family2.html" class="btn btn-icon btn-outline">
              <i class="fa fa-bullhorn" aria-hidden="true"></i> Request Revit Family </a>
            <a href="requested family0.html" class="btn btn-icon btn-outline">
              <i class="fa fa-gift" aria-hidden="true"></i>Contribute Revit Family </a>
          </div>
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="families2.html">
                  <figure>
                    <img src="images/f/04.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                
                  </div>
               
                </a>
                
              </div>
              
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Return Diffuser</strong>
              </div>
            </div>
           
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/f/06.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                
                </a>
               
              </div>
            
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Extract Diffuser</strong>
              </div>
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/f/02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
             
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Round Diffuser</strong>
              </div>
            </div>
         
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/f/03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                
                </a>
                
              </div>
              
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Louver</strong>
              </div>
            </div>
           
          </div>
         
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/f/06.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Linear Slot Diffuser</strong>
              </div>
            </div>
        
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                  
                  </div>
                
                </a>
            
              </div>
            
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Linear Slot Diffuser</strong>
              </div>
            </div>
         
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                  
                  </div>
                
                </a>
              
              </div>
           
              <div style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Linear Slot Diffuser</strong>
              </div>
            </div>
          
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                 
                </a>
              
              </div>
        
              <div  style={{textAlign: "center" , marginTop:"10px"}}>
                <strong>Linear Slot Diffuser</strong>
              </div>
            </div>
         
          </div>
        
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
               
                  </div>
                
                </a>
              
              </div>
           
            </div>
          
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
            
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
            
            </div>
           
          </div>
        
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                  
                  </div>
                
                </a>
            
              </div>
             
            </div>
         
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
             
            </div>
        
          </div>
          
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                
                </a>
                
              </div>
            
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
            
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
            
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
          </div>
        
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
            
                </a>
                
              </div>
             
            </div>
         
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                
                </a>
               
              </div>
            
            </div>
           
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
          
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                 
                </a>
              
              </div>
              
            </div>
        
          </div>
      
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
        
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
          </div>
        
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                 
                </a>
              
              </div>
             
            </div>
          
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                 
                </a>
              
              </div>
           
            </div>
          
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                 
                </a>
              
              </div>
             
            </div>
           
          </div>
         
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                
                  </div>
                  
                </a>
                
              </div>
             
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                  
                </a>
              
              </div>
             
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                 
                </a>
              
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
        
          </div>
          
          <br />
          <div class="row portfolio-gallery">
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-01.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-01.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                   
                  </div>
                 
                </a>
               
              </div>
              
            </div>
           
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-02.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-02.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>

                  </div>
                 
                </a>
                
              </div>
             
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
            <div class="col col-xs-12 col-sm-3">
              <div class="portfolio-box">
                <div class="portfolio-btn portfolio-btn1">
                  <a href="#">
                    <i class="fa fa-eye"> 12k</i>
                  </a>
                  <a href="#">
                    <i class="fa fa-download"> 235</i>
                  </a>
                </div>
                <a href="images/project-03.jpg" class="portfolio-block">
                  <figure>
                    <img src="images/project-03.jpg" alt="" />
                  </figure>
                  <div class="portfolio-overlay">
                    <div class="portfolio-content"></div>
                    
                  </div>
                 
                </a>
               
              </div>
             
            </div>
           
          </div>
          
          <br />
          <br />
          <div class="row">
            <div class="col col-xs-12 text-center">
              <a class="popup-youtube btn btn-dark" href="#">Browse More</a>
              <br/>
            </div>
          </div>
          <br />
        </div>
     
        <aside class="col col-md-3 blog-sidebar">
          <br />
          <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
            <div class="sidebar-search">
              <form action="#">
                <input class="form-control" type="search" placeholder="Search here..." />
                <button type="submit" class="btn-search">
                  <i class="fa fa-search"></i>
                </button>
              </form>
            </div>
           
            <br />
            <h4>CATEGORIES</h4>
            <ul class="list list-style-01 text-uppercase text-md">
              <li>
                <a href="#">AIR TERMINAL</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">CABLE TRAY FITTINGS</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">CASE WORK </a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
       
          <div class="sidebar-widget  m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
            <h4>SORT BY</h4>
            <ul class="list list-style-01 text-uppercase text-md">
              <li>
                <a href="#">ALPHABETS ORDER</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">UPLOADED DATE</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">POPULARITY</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
       
          <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
            <h4>Version</h4>
            <ul class="list list-style-01 text-uppercase text-md">
              <li>
                <a href="#">2020</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">2019</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">2018</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">2017 or Earlier</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
       
          <div class="sidebar-widget  m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
            <h4>Host Type</h4>
            <ul class="list list-style-01 text-uppercase text-md">
              <li>
                <a href="#">Hosted</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                  </div>
                </span>
              </li>
              <li>
                <a href="#">Non Hosted</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
          
          <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
            <h4>Sub Descipline</h4>
            <ul class="list list-style-01 text-uppercase text-md">
              <li>
                <a href="#">HVAC</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                  </div>
                </span>
              </li>
              <li>
                <a href="#">DRAINAGE</a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
              <li>
                <a href="#">FIRE FIGHTING </a>
                <span class="number">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </aside>
       
      </div>
     
    </div>
   
  </div>
  
</div>



    
    
    </div>

     </div>
  );
}

export default Families;
