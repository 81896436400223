import React,{useState} from 'react'
import Footer from "../../components/footer";
import Header from "../../components/header";
import HeaderSecond from "../../components/Secondheader.js"


function Signup() {





  return (
    <div className="App">

<HeaderSecond/>



    <div id="main-wrapper">

        <div class="authincation section-padding">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center" style={{paddingTop: "100px"}}>
                    <div class="col-md-3"></div>
                    <div class="col-xl-6 col-md-5 form-width">
                            <div class="mini-logo text-center my-5">
                                <a href="index.html"><img src="./images/logo.png" alt="" /></a>
                            </div>
                        <div class="auth-form card">
                            <div class="card-header justify-content-center">
                                <h4 class="modal-title mb-5" id="myModalLabel">Sign up</h4>
                            </div>
                            <div class="card-body">
                                <form action="#" id="hireform" method="post">
                                    <div class="row">

                                    <div class="col col-sm-12">
                                      <div class="input-field has-icon"> <i class="fa fa-user"></i>
                                        <input type="text" required class="form-control" name="hirename" placeholder="Name" />
                                      </div>
                                    
                                    </div>
                                  
                                    <div class="col col-sm-12">
                                      <div class="input-field has-icon"> <i class="fa fa-mobile"></i>
                                        <input type="tel" required class="form-control" name="hirephone" placeholder="Email" />
                                      </div>
                                      
                                    </div>
                                   
                                    <div class="col col-sm-12">
                                      <div class="input-field has-icon"> <i class="fa fa-mobile"></i>
                                        <input type="tel" required class="form-control" name="hirephone" placeholder="Password" />
                                      </div>
                                     
                                    </div>
                                   
                                    <div class="col col-sm-12">
                                      <div class="input-field has-icon"> <i class="fa fa-mobile"></i>
                                        <input type="tel" required class="form-control" name="hirephone" placeholder="Confirm Password" />
                                      </div>
                                     
                                    </div>


                                    <div class="col col-sm-12">
                                      <ul class="list list-style-02 ">
                                    		<li>
                                    				<div class="form-check" style={{paddingLeft: "20px"}}  >
                                    					<input type="checkbox" class="form-check-input" id="exampleCheck1"/><a href="reset.html" style={{paddingLeft: "10px"}}  class="formfont-style">By creating an account you agree to our User Terms and Conditions </a>
                                    				</div>
                                    			
                                    		</li>
                                      </ul>
                                         <br/>

                                   
                                    <div class="col col-xs-12 text-center">
                                      <button type="submit" class="btn btn-dark btn-lg btn-submit">Submit</button>
                                    </div>
                                   
                                   
                                   
                                  </div>
                                  </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        </div>


    </div>
	 
 
    <Footer/>


     </div>
  );
}

export default Signup;
