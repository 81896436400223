import React,{useState} from 'react'


function Header() {





  return (
    <div className="App">

<header id="header">
        <nav class="dashboard-navabar m-0">
          <div class="navbar-menu">
            <a class="menu-btn" onclick="drawerAction()" 
              ><i class="fa fa-bars"></i>
            </a>
            <div class="navbar-menu-drawer" id="navbar-menu-drawer-">
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
              <div class="navbar-menu-item">
                <i class="fa fa-facebook"></i>
                <span>Facebook</span>
              </div>
            </div>
          </div>
          <button  class="login-btn">
           <a href="/signin"> <i class="fa fa-sign-out"></i>Login</a>
          </button>
        </nav>
      </header>


     
	  
	
	  
	 
 


     </div>
  );
}

export default Header;
