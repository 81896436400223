import React,{useState} from 'react'





function Footer() {


  return (
    <div className="App">

<footer class="index-footer">
        <div class="index-footer-item">
          <a href="about.html">About</a>
        </div>
        <div class="index-footer-item">
          <a href="about.html">Advertising</a>
        </div>
        <div class="index-footer-item">
          <a href="about.html">Business</a>
        </div>
        <div class="index-footer-item">
          <a href="about.html">How Search Works</a>
        </div>
        <div style={{flex: "1"}}></div>
        <div class="index-footer-item">
          <a href="about.html">Privacy</a>
        </div>
        <div class="index-footer-item">
          <a href="about.html">Terms</a>
        </div>
        <div class="index-footer-item">
          <a href="about.html">Settings</a>
        </div>
      </footer>
  





     </div>
  );
}

export default Footer;
