import React,{useState} from 'react'
import HeaderSecond from "../../components/Secondheader.js"
import Footer from "../../components/footer.js"

function Calculation() {





  return (
    <div className="App"> 

    <div id="wrapper">
        <HeaderSecond/>


  
        <div id="main">
      <div id="lightbox" class="section bg-gray">
        <div class="container">
          <div class="row animated-row">
            <div class="col col-md-9 blog-col calculation-mob">
              <div class="row contribute-header">
                <div class="cta-row"> <a href="#" class="btn btn-icon btn-outline"><i
                      class="glyphicon glyphicon glyphicon-bullhorn"></i> Request Document </a> </div>
                <div class="cta-row"> <a href="#" class="btn btn-icon btn-outline"><i
                      class="glyphicon glyphicon glyphicon-gift"></i> Contribute Document </a> </div>
                <div class="sidebar-search d-block">
                  <form action="#"> <input class="form-control" type="search" placeholder="Search here..." /> <button
                      type="submit" class="btn-search"><i class="fa fa-search"></i></button> </form>
                </div>
                <div class="d-none" style={{width:"100%"}} >
                  <div class="mobile-filter">
                    <form action="#"> <input class="form-control" type="search" placeholder="Search here..." /> <button
                      type="submit" class="btn-search"><i class="fa fa-search"></i></button> </form>
                      <h4 class="calculation-filter "> <a data-toggle="collapse" data-parent="#accordion-01"
                        href="#collapse5">Filter<span class="pull-right icon-acc"><i
                            class="fa fa-angle-down"></i></span></a></h4>
                  </div>
                </div>
                
                
                <div id="collapse5" class="panel-collapse collapse calculation-collapse">
                  <div class="panel-body">
                    <div class="col col-md-3 blog-sidebar"> 
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>Categories</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">Architecture</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span> </li>
                          <li><a href="#">Structure</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Mechanical </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Electrical </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Plumbing </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Fire protection </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">General </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>

                        </ul>
                      </div>
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>Sub Categories</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">HVAC</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span> </li>
                          <li><a href="#">Lighting</a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Power </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Water Supply </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                          <li><a href="#">Drainage </a> <span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>



                        </ul>
                      </div>
                      <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                        <h4>SORT BY</h4>
                        <ul class="list list-style-01 text-uppercase text-md">
                          <li><a href="#">ALPHABETS ORDER</a><span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span> </li>
                          <li><a href="#">UPLOADED DATE</a><span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1"/>
                              </div>
                            </span></li>
                          <li><a href="#">POPULARITY</a><span class="number">
                              <div class="form-check"> <input type="checkbox" class="form-check-input"
                                  id="exampleCheck1" />
                              </div>
                            </span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row portfolio-gallery">
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">PDF</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">PDF</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">PDF</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">DOC</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">XLSX</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">PDF</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">PDF</div>
                    </figure> <a href="calculation1.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
                <div class="col col-xs-12 col-sm-3">
                  <div class="portfolio-card">
                    <figure> <a href="calculation1.html"> <img src="images/project-02.jpg" alt="" /></a>
                      <div class="portfolio-card-type">PDF</div>
                    </figure> <a href="r&s2.html">
                      <h2>Car parking Ventilation Calculation</h2>
                    </a>
                    <ul>
                      <li>Dn ut ullamcorper leo, eget o sita nibh vulputate uo adip nibh vulputate uo adip .. </li>
                    </ul>
                    <div class="portfolio-card-footer">
                      <div class="portfolio-card-footer-item"> <i class=" fa fa-eye"></i> 100 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-comment"></i> 344 </div>
                      <div class="portfolio-card-footer-item"> <i class="glyphicon glyphicon-arrow-down"></i> 123 </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside class="col col-md-3 blog-sidebar"> <br /> <br />
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Categories</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">Architecture</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span> </li>
                  <li><a href="#">Structure</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">Mechanical </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">Electrical </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">Plumbing </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                      </div>
                    </span></li>
                  <li><a href="#">Fire protection </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">General </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>

                </ul>
              </div>
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Sub Categories</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">HVAC</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span> </li>
                  <li><a href="#">Lighting</a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                      </div>
                    </span></li>
                  <li><a href="#">Power </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">Water Supply </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">Drainage </a> <span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>



                </ul>
              </div>
              <div class="sidebar-widget d-block m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>SORT BY</h4>
                <ul class="list list-style-01 text-uppercase text-md">
                  <li><a href="#">ALPHABETS ORDER</a><span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span> </li>
                  <li><a href="#">UPLOADED DATE</a><span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                      </div>
                    </span></li>
                  <li><a href="#">POPULARITY</a><span class="number">
                      <div class="form-check"> <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                      </div>
                    </span></li>
                </ul>
              </div>

              <div class="sidebar-widget m-b-20 p-20 animate" data-animate="fadeInUp">
                <h4>Best Contributor of THE MONTH</h4>
                <div class="experience-list">
                  <div class="experience-row1 clearfix animated fadeIn" data-animate="fadeIn">
                    <div class="client-imgbox2">
                      <figure><img src="images/client-02.png" alt="" /></figure>
                    </div>
                    <div class="experience-details experience-details1 overflowhidden font-secondary"> <br/> <strong
                        class="text-uppercase text-black">SUHAIL ABOOBACKER</strong> <span>BIM Engineer</span> </div>
                    
                  </div>
                  

                </div>
              </div>
              
              <div class="sidebar-widget  m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Help BIM Mates</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                  industry's standard. Lorem Ipsum has been the industry's standard</p>
              </div>
              <div class="sidebar-widget m-b-20 p-20 animate" data-animate="fadeInUp">
                <h4>Best Contributor of THE MONTH</h4>

                <div class="experience-list">
                  <div class="experience-row1 clearfix animated fadeIn" data-animate="fadeIn">
                    <div class="client-imgbox2">
                      <figure><img src="images/client-02.png" alt="" /></figure>
                    </div>
                    <div class="experience-details experience-details1 overflowhidden font-secondary"> <br/> <strong
                        class="text-uppercase text-gray">SUHAIL ABOOBACKER</strong> <span>BIM Engineer</span> </div>
                    
                  </div>
                  

                </div>
              </div>
              




              <div class="sidebar-widget m-b-30 md-m-b-20 p-30 md-p-20 animate" data-animate="fadeInUp">
                <h4>Help BIM Mates</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                  industry's standard. Lorem Ipsum has been the industry's standard</p>
              </div>
            </aside>
            
          </div>
          
        </div>
      
      </div>
    
    </div>
	


<Footer/>




    
    
    </div>

     </div>
  );
}

export default Calculation;
