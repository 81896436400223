import React,{useState} from 'react'
import Footer from "../components/footer.js"
import Header from "../components/header.js"

function Home() {





  return (
    <div className="App">

<Header/>


      <div id="main">
        <div id="lightbox" class="section bg-gray">
          <div class="container">
            <div class="home-page">
              <div class="home-page-left">
                <h1>BIMMATES</h1>
                <div class="home-page-input">
                  <i class="fa fa-search"></i>
                  <input />
                  <i class="fa fa-microphone"></i>
                </div>
                <div class="btn-list text-center m-30 p-b-10 home-page-icons">
                  <a
                    href="/families"
                    class="btn btn-icon btn-outline"
                    ><i class="fa fa-bullhorn" aria-hidden="true"></i
                    ><span>REVIT FAMILIES </span></a
                  >
                  <a
                    href="requested "
                    class="btn btn-icon btn-outline"
                    ><i class="fa fa-bullhorn" aria-hidden="true"></i
                    ><span>BIM Companies</span></a
                  >
                  <a
                    href="requested "
                    class="btn btn-icon btn-outline"
                    ><i class="fa fa-bullhorn" aria-hidden="true"></i
                    ><span>BIM Companies</span></a
                  >
                  <a
                    href="requested family2.html"
                    class="btn btn-icon btn-outline"
                    ><i class="fa fa-bullhorn" aria-hidden="true"></i
                    ><span>BIM Companies</span></a
                  >
                </div>
              </div>
              <div class="home-page-right">
                <h1>Are you a bim professional?</h1>
                <p>
                  Get free bim documents, libraries and connect your profile in
                  bimmates & be discover. Cheers !
                </p>
                <a href="/signup">Register</a>
              </div>
            </div>
          </div>
        </div>
      </div>
	  
	  

<Footer/>


      <div
        class="modal fade"
        id="hireme"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header align-center">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="myModalLabel">Hire me</h4>
            </div>
            <div class="modal-body">
              <form action="#" id="hireform" method="post">
                <div class="row">
                  <div class="col col-sm-6">
                    <div class="input-field has-icon">
                      <i class="fa fa-user"></i>
                      <input
                        type="text"
                        required
                        class="form-control"
                        name="hirename"
                        placeholder="Name"
                      />
                    </div>
                    
                  </div>
                  
                  <div class="col col-sm-6">
                    <div class="input-field has-icon">
                      <i class="fa fa-mobile"></i>
                      <input
                        type="tel"
                        required
                        class="form-control"
                        name="hirephone"
                        placeholder="Phone"
                      />
                    </div>
                   
                  </div>
                
                  <div class="col col-sm-6 col-xs-12">
                    <div class="input-field has-icon">
                      <i class="fa fa-envelope"></i>
                      <input
                        type="email"
                        required
                        class="form-control"
                        name="hireemail"
                        placeholder="Email"
                      />
                    </div>
                   
                  </div>

                  <div class="col col-sm-6 col-xs-12">
                    <div class="input-field has-icon">
                      <i class="fa fa-server"></i>
                      <div class="custom-select">
                        <select>
                          <option>What are you interested in?</option>
                          <option>Design</option>
                          <option>Development</option>
                          <option>SEO</option>
                        </select>
                      </div>

                    </div>
                    
                  </div>
                  
                  <div class="col col-xs-12">
                    <div class="input-field has-icon">
                      <i class="fa fa-file-text"></i>
                      <textarea
                        class="form-control"
                        required
                        name="hiremessage"
                        placeholder="Decribe your project"
                      ></textarea>
                    </div>
                   
                  </div>
                 
                  <div class="col col-xs-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-dark btn-md btn-submit"
                    >
                      Submit
                    </button>
                  </div>
                 
                  <div class="col col-xs-12">
                    <div id="loading01">Sending your message....</div>
                    <div id="successmsg01">Your message has been sent.</div>
                    <div id="errormsg01">
                      Something went wrong, please try again.
                    </div>
                  </div>
                 
                </div>
               
              </form>
            </div>
          </div>
        </div>
      </div>
	  
	 
 


     </div>
  );
}

export default Home;
